'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/boxes', {
        templateUrl: 'app/boxes/list.html',
        controller: 'BoxesController',
        authenticate: 'user',
        hasPermissions: ['BOXES:GET']
    }).when('/boxes/partners', {
        templateUrl: 'app/boxes/partners/list.html',
        controller: 'BoxPartnersController',
        authenticate: 'user',
        hasPermissions: ['BOXES:GET']
    });
});
